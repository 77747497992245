import { UserConsumerHook } from 'shared/stores/User';

const AccountUtils = {

    getCategoryLabel: function(account) {
        return account.category_label ? account.category_label : "Catégorie";
    },
    
    getSubCategoryLabel: function(account) {
        return account.subcategory_label ? account.subcategory_label : "Sous-catégorie";
    },
    
    getCategoriesLabel: function(account) {
        return AccountUtils.getCategoryLabel(account)  + " / " + AccountUtils.getSubCategoryLabel(account);
    },

    isAccountConsultant: function(account) {
        const [user] = UserConsumerHook();
        if (account && user.roles.includes("ROLE_CONSULTANT")) {
            const userId = user.id;
            const allConsultants = Array.prototype.concat(account.associate_consultants, account.pilot_consultants);
            return allConsultants.find((element) => element.id === userId);
        }
        return false;
    }
}

export default AccountUtils;